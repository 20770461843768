module.exports = [{
      plugin: require('/home/travis/build/GraphQL-China/principled-graphql/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"node_modules/gatsby-theme-apollo/src/util/typography.js"},
    },{
      plugin: require('/home/travis/build/GraphQL-China/principled-graphql/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-74643563-15"},
    },{
      plugin: require('/home/travis/build/GraphQL-China/principled-graphql/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/GraphQL-China/principled-graphql/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false},
    }]
